.restaurant {
  position: relative;
  padding: 60px 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: fadeIn 3s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &.light-mode {
    background-color: #f0f0f0;
    color: black;

    .restaurant-description {
      color: black;
    }

    .restaurant-main-title,
    .restaurant-restarrow {
      color: #e19e51;
    }

    .restaurant-right-section {
      border-left: 5px solid #e19e51;
      padding-left: 20px;

      .restaurant-slideshow {
        box-shadow: 0 4px 8px rgba(225, 158, 81, 0.2);
        border: 2px solid rgba(225, 158, 81, 0.5);
        border-radius: 10px;
      }
    }
  }

  &.dark-mode {
    background-color: #040c0c;
    color: white;

    .restaurant-description {
      color: white;
    }

    .restaurant-main-title,
    .restaurant-restarrow {
      color: #e19e51;
    }

    .restaurant-right-section {
      border-left: 5px solid #e19e51;
      padding-left: 20px;

      .restaurant-slideshow {
        box-shadow: 0 4px 8px rgba(225, 158, 81, 0.2);
        border: 2px solid rgba(225, 158, 81, 0.5);
        border-radius: 10px;
      }
    }
  }

  .restaurant-slideshow {
    position: relative;
    width: 100%;
    height: 500px; // Adjust height as needed
    overflow: hidden;
    transition: transform 0.5s ease-in-out;

    .restaurant-slide {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity 1s ease-in-out;
    }

    .restaurant-slide-active {
      opacity: 1;
      position: relative;
      animation: zoomIn 1.5s ease;
    }

    @keyframes zoomIn {
      from {
        transform: scale(0.8);
        opacity: 0;
      }
      to {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

.restaurant-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1000px;
  padding: 20px;
  animation: fadeInContent 2s ease-in-out;

  @keyframes fadeInContent {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.restaurant-left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-right: 60px;
  animation: fadeInLeft 1.5s ease-in-out;

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.restaurant-right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  border-left: 5px solid transparent;
  padding-left: 20px;
  animation: fadeInRight 1.5s ease-in-out;

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.restaurant-title-with-restarrow {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.restaurant-restarrow {
  font-family: 'Playfair Display', serif;
  font-weight: 500;
  font-size: 24px;
  color: #e19e51;
}

.restaurant-main-title {
  font-family: 'Playfair Display', serif;
  font-weight: 500;
  font-size: 32px;
  color: #e19e51;
  margin: 0;
}

.restaurant-description {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  max-width: 550px;
}

.dark-mode-toggle {
  background: #e19e51;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

/* Added media query to move slideshow under description on mobile */
@media (max-width: 768px) {
  .restaurant {
    text-align: center;
  }

  .restaurant-container {
    flex-direction: column;
    align-items: center;
  }

  .restaurant-left-section,
  .restaurant-right-section {
    flex: 1 1 100%;
    text-align: center;
    align-items: center;
  }

  .restaurant-main-title {
    font-size: 28px;
    text-align: center;
  }

  .restaurant-description {
    font-size: 14px;
    max-width: 100%;
  }

  .restaurant-title-with-restarrow {
    flex-direction: column;
    align-items: center;
  }

  .restaurant-restarrow {
    display: none; /* Hide arrow on mobile screens */
  }

  .restaurant-left-section {
    margin-right: 0;
  }

  .restaurant-slideshow {
    margin-top: 20px; /* Added margin to separate from text */
  }
}