.hero {
    position: relative;
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.hero-image {
    display: flex;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    justify-content: center;
    align-items: center;
    animation: slideIn 0.8s ease-out;
    flex-direction: column; /* Ensure column layout */
}

@keyframes slideIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        translateY: 0;
        opacity: 1;
    }
}

.hero-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(100%);
    position: absolute;
    z-index: 0;
}

.hero-image::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.hero-image .left-section {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Playfair Display', serif;
    font-weight: 500;
    font-size: 48px;
    text-align: center;
    position: relative;
    z-index: 2;
    animation: fadeInText 1s ease-in-out forwards;
    color: #e19e51;

}

@keyframes fadeInText {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.hero-image .right-section {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    animation: fadeInText 1.2s ease-in-out forwards;
    margin-top: 20px; /* Add some spacing */
}

.hero-button {
    background-color: #ffffff; /* Button background color */
    border: none;
    border-radius: 25px;
    color: #333333; /* Button text color */
    font-size: 20px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.hero-button:hover {
    background-color: #f3f3f3; /* Button background color on hover */
    transform: scale(1.05); /* Slightly enlarge button on hover */
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
    .hero-image .right-section .hero-button {
        font-size: 18px;
        padding: 8px 16px;
    }
}

@media (max-width: 768px) {
    .hero-image {
        height: 100vh; /* Ensure full screen height on smaller devices */
        align-items: center;
        text-align: center; /* Ensure text gets centered */
    }

    .hero-image img {
        height: 100%;
    }

    .hero-image .left-section,
    .hero-image .right-section {
        font-size: 24px;
        padding-bottom: 40px;
        width: 100%;
    }

    .hero-image .right-section .hero-button {
        font-size: 16px;
        padding: 6px 12px;
    }
}

@media (max-width: 480px) {
    .hero-image {
        height: 80vh; /* Ensure full screen height on smallest devices */
    }

    .hero-image .right-section .hero-button {
        font-size: 14px;
        padding: 4px 8px;
    }
}