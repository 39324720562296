.playroom {
  padding: 3rem 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  animation: fadeIn 3s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(225, 158, 81, 0.2), rgba(255, 255, 255, 0));
    pointer-events: none; // So it doesn't block any clicks
    z-index: -1; // Send it to the background
  }

  &.light-mode {
    background-color: #f0f0f0;
    color: black;

    .playroom-description {
      color: black;
    }

    .playroom-content {
      .playroom-slideshow {
        box-shadow: 0 4px 8px rgba(225, 158, 81, 0.2);
        border: 2px solid rgba(225, 158, 81, 0.5);
      }

      .playroom-description {
        border-left: 5px solid #e19e51;
        padding-left: 20px;
      }
    }
  }

  &.dark-mode {
    background-color: #040c0c;
    color: white;

    .playroom-description {
      color: white;
    }

    .playroom-content {
      .playroom-slideshow {
        box-shadow: 0 4px 8px rgba(225, 158, 81, 0.2);
        border: 2px solid rgba(225, 158, 81, 0.5);
      }

      .playroom-description {
        border-left: 5px solid #e19e51;
        padding-left: 20px;
      }
    }
  }

  .playroom-title {
    font-family: 'Playfair Display', serif;
    font-weight: 500;
    font-size: 36px;
    color: #e19e51;
    margin-bottom: 4rem; /* Increased gap */
  }

  .playroom-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1100px;
    margin: 0 auto;
    gap: 50px; /* Space between slideshow and text */
    padding: 0 20px;
    animation: fadeInContent 2s ease-in-out;

    @keyframes fadeInContent {
      from {
        opacity: 0;
        transform: translateY(30px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .playroom-slideshow {
    flex: 1;
    max-width: 50%;
    height: 300px;
    overflow: hidden;
    border-radius: 10px;
    animation: slideInFromBottom 1.2s ease-out;
    transition: transform 0.5s ease-in-out;

    @keyframes slideInFromBottom {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .playroom-slide {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .playroom-description {
    flex: 1;
    max-width: 50%;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    padding-top: 1rem;
    line-height: 1.5;
    text-align: left;
    margin: 0 auto;
    border-left: 5px solid transparent; // To keep the same alignment in both modes
    padding-left: 20px;
    animation: fadeInLeft 1.5s ease-in-out;

    @keyframes fadeInLeft {
      from {
        opacity: 0;
        transform: translateX(-20px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  .dark-mode-toggle {
    background: #e19e51;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
  }

  /* Responsive design */
  @media (max-width: 768px) {
    .playroom-content {
      flex-direction: column;
      align-items: center;
    }

    .playroom-description {
      order: 1; // Ensure the description is first
      max-width: 100%;
      text-align: center;
      border-left: none;
      padding-left: 0;
    }

    .playroom-slideshow {
      order: 2; // Ensure the slideshow is second
      max-width: 100%;
      text-align: center;
    }
  }
}