.wellness-center {
  padding: 3rem 0;
  position: relative;
  overflow: hidden;
  animation: fadeIn 2.5s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;
  }

  &.light-mode {
    background-color: #f0f0f0;
    color: black;

    .description {
      color: black;
    }

    .right-section .wellslideshow {
      box-shadow: 0 4px 8px rgba(225, 158, 81, 0.2);
      border: 2px solid rgba(225, 158, 81, 0.5);
      border-radius: 10px;
      animation: slideInFromRight 1.2s ease-out;

      @keyframes slideInFromRight {
        from {
          opacity: 0;
          transform: translateX(30px);
        }
        to {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }

    .left-section .description {
      border-left: 5px solid #e19e51;
      padding-left: 20px;
      animation: fadeInLeft 1.5s ease-in-out;

      @keyframes fadeInLeft {
        from {
          opacity: 0;
          transform: translateX(-20px);
        }
        to {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }

  &.dark-mode {
    background-color: #040c0c;
    color: white;

    .description {
      color: white;
    }

    .right-section .wellslideshow {
      box-shadow: 0 4px 8px rgba(225, 158, 81, 0.2);
      border: 2px solid rgba(225, 158, 81, 0.5);
      border-radius: 10px;
      animation: slideInFromRight 1.2s ease-out;

      @keyframes slideInFromRight {
        from {
          opacity: 0;
          transform: translateX(30px);
        }
        to {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }

    .left-section .description {
      border-left: 5px solid #e19e51;
      padding-left: 20px;
      animation: fadeInLeft 1.5s ease-in-out;

      @keyframes fadeInLeft {
        from {
          opacity: 0;
          transform: translateX(-20px);
        }
        to {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }

  .wellness-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    gap: 60px;
    animation: fadeInContent 1.8s ease-in-out;

    @keyframes fadeInContent {
      from {
        opacity: 0;
        transform: translateY(30px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .left-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title-container {
      display: flex;
      align-items: center;
      gap: 1rem;

      .main-title {
        font-family: 'Playfair Display', serif;
        font-weight: 500;
        font-size: 36px;
        color: #e19e51;
        animation: fadeIn 2.2s ease-in-out;
      }

      .arrow {
        font-size: 24px;
        color: #e19e51;
        animation: bounce 5s infinite;

        @keyframes bounce {
          0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-10px);
          }
          60% {
            transform: translateY(-5px);
          }
        }
      }
    }

    .description {
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      margin-top: 1rem;
    }
  }

  .right-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .wellslideshow {
      position: relative;
      width: 100%;
      height: 400px;
      overflow: hidden;
      transition: transform 0.5s ease-in-out;

      .slide {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity 1s ease-in-out;
      }

      .slide.active {
        opacity: 1;
        position: relative;
      }
    }
  }

  .dark-mode-toggle {
    background: #e19e51;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    .wellness-container {
      flex-direction: column;
      align-items: center;
      padding: 0 1rem;
    }

    .left-section,
    .right-section {
      max-width: 100%;
      text-align: center;
      align-items: center;
    }

    .main-title {
      font-size: 28px;
    }

    .description {
      font-size: 14px;
      border-left: none;
      padding: 0 10px;
    }

    .title-container {
      flex-direction: column;
      align-items: center;
    }

    .arrow {
      display: none; /* Hide arrow on mobile screens */
    }

    .right-section .wellslideshow {
      width: 480px;
      height: 400px;
      margin-left: 0;
    }

    .right-section .slide {
      width: 480px;
      height: 400px;
    }

    .dark-mode-toggle {
      margin-top: 10px;
    }
  }

  @media (max-width: 480px) {
    .right-section .wellslideshow {
      width: 480px;
      height: 400px;
    }

    .right-section .slide {
      width: 480px;
      height: 400px;
    }
  }
}