.footer {
  background-color: #040c0c;
  color: white;
  padding: 3rem 0;
  text-align: center; /* Center all text by default */
  background: linear-gradient(135deg, #040C0C 30%, #e19e51 100%);

  .line {
    width: 60%;
    height: 1px;
    background-color: white;
    margin: 0 auto 2rem;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    gap: 2rem;
    text-align: left; /* Left-align text for larger screens */


    .contact-info {
      flex: 1;
      text-align: left; /* Left-align text for larger screens */

      h1 {
        font-size: 24px;
        margin-bottom: 1rem;
        color: #e19e51;
      }

      p {
        font-size: 16px;
        margin: 5px 0;
      }

      .social-icons {
        margin-top: 1rem;
        a {
          color: white;
          margin: 0 1rem;
          &:hover {
            color: #e19e51;
          }
        }
      }
    }

    .google-map {
      flex: 1;
      iframe {
        border: none;
        max-width: 100%;
        width: 100%;
        height: 300px;
      }
    }
  }

  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
      text-align: center; /* Center text for mobile views */

      .contact-info {
        text-align: center; /* Center text for mobile views */
      }

      .google-map iframe {
        width: 100%;
        height: 250px;
      }
    }
  }
}