/* General Navbar Styles */
html {
  scroll-behavior: smooth;
}

body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

body.light-mode {
  background-color: #f5f5dc; /* Light beige background color for the light mode */
  color: #333333; /* Softer text color */
}

.contact-button {
  background-color: #e19e51;
  color: #333333; /* Darker text color for better contrast */
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none; /* Remove underline from the anchor */
  display: inline-block; /* Make it behave like a button */
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f9a825;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-family: 'Josefin Sans', sans-serif;
  height: 80px;
  position: relative;
  background: linear-gradient(135deg, #040C0C 30%, #e19e51 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar.light {
  color: #ffffff; /* White text color */
}

.navbar.dark {
  color: #ffffff; /* White text color */
}

.navbar-logo {
  display: flex;
  align-items: center;
  pointer-events: none;
}

.logo-image {
  margin-right: 10px;
  width: 40px;
  height: auto;
}

.logo-text {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  color: #e19e51;
  font-size: 24px;
}

.navbar-hamburger {
  cursor: pointer;
  display: none;
}

.hamburger-line {
  display: block;
  width: 30px;
  height: 2px;
  background-color: currentColor;
  margin: 5px 0;
}

.navbar-menu-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.navbar-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.navbar-categories {
  list-style-type: none;
  display: flex;
  gap: 50px;
  padding: 0;
  margin: 0;
}

.navbar-categories li a {
  color: currentColor;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  transition: color 0.3s ease;
}

.navbar-categories li a:hover {
  color: #e19e51;
}

.navbar-language {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 20px;
  margin-left: -100px;
}

.navbar-language select {
  background-color: transparent;
  color: inherit;
  border: 1px solid #e19e51;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  background-color: #040c0c;
}

.navbar-language button {
  background-color: #e19e51;
  color: #333333; /* Darker text color for better contrast */
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.navbar-language button:hover {
  background-color: #f9a825;
}

.dark-mode-toggle {
  transform: translateY(-9px); /* Adjust value as needed to move upwards */
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 10px 20px;
    height: auto;
  }

  .navbar-logo {
    margin-bottom: 20px;
  }

  .navbar-hamburger {
    display: block;
    margin-bottom: 20px;
  }

  .navbar-menu-wrapper {
    flex-direction: column;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  .navbar-menu-wrapper.open {
    max-height: 1000px;
  }

  .navbar-menu {
    flex-direction: column;
  }

  .navbar-categories {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .navbar-language {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 20px;
    margin-left: 0px;
  }

  .navbar-language select,
  .navbar-language button {
    width: 80%;
    text-align: center;
  }

  .dark-mode-toggle {
    transform: translateY(-9px); /* Adjust value as needed to move upwards */
    margin-left: 0;
    align-self: center; /* Center the toggle button */
  }
}