.accommodation {
  position: relative;
  padding: 60px 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: fadeIn 3s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  &.fade-out {
    animation: fadeOut 3s ease-in-out;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(225, 158, 81, 0.2), rgba(255, 255, 255, 0));
    pointer-events: none; // So it doesn't block any clicks
    z-index: -1; // Send it to the background
  }

  &.light-mode {
    background-color: #f0f0f0;
    color: black;

    .accommodation-description {
      color: black;
    }

    .accommodation-main-title {
      font-family: 'Playfair Display', serif;
      font-weight: 500;
      font-size: 32px;
      color: #e19e51;
      margin-bottom: 10px; /* Reduced gap between title and description */
    }

    .accommodation-main-title,
    .accommodation-arrow,
    .accommodation-sub-title {
      color: #e19e51;
    }

    .accommodation-left-section {
      border-left: 5px solid #e19e51;
      padding-left: 20px;


    }

    .accommodation-acomslideshow {
      box-shadow: 0 4px 8px rgba(225, 158, 81, 0.2);
      border: 2px solid rgba(225, 158, 81, 0.5);
    }
  }

  &.dark-mode {
    background-color: #040c0c;
    color: white;

    .accommodation-description {
      color: white;
    }

    .accommodation-main-title,
    .accommodation-arrow,
    .accommodation-sub-title {
      color: #e19e51;
    }

    .accommodation-main-title {
      margin-bottom: 10px; /* Reduced gap between title and description */
    }

    .accommodation-left-section {
      border-left: 5px solid #e19e51;
      padding-left: 20px;
    }

    .accommodation-acomslideshow {
      box-shadow: 0 4px 8px rgba(225, 158, 81, 0.2);
      border: 2px solid rgba(225, 158, 81, 0.5);
    }
  }
}

.accommodation-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  animation: fadeInContent 1.5s ease-in-out;

  @keyframes fadeInContent {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeOutContent {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(30px);
    }
  }

  &.fade-out {
    animation: fadeOutContent 1.5s ease-in-out;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.accommodation-left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadeInLeft 1s ease-in-out;
  text-align: center;
  margin-bottom: 20px;

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fadeOutLeft {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(-20px);
    }
  }

  &.fade-out {
    animation: fadeOutLeft 1s ease-in-out;
  }

  @media (min-width: 768px) {
    align-items: flex-start;
    text-align: left;
    margin-bottom: 0;
  }
}

.accommodation-right-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  animation: fadeInRight 1s ease-in-out;

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fadeOutRight {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(20px);
    }
  }

  &.fade-out {
    animation: fadeOutRight 1s ease-in-out;
  }

  @media (min-width: 768px) {
    width: 50%;
    margin-left: 20px;
  }
}

.accommodation-acomslideshow {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 10px;

  .accommodation-slide {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 1s ease-in-out;
  }

  .accommodation-slide.active {
    opacity: 1;
    position: relative;
  }

  @media (min-width: 768px) {
    height: 500px; // Increase the height for desktop view
  }
}

.accommodation-slide {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.dark-mode-toggle {
  background: #e19e51;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

@media (max-width: 768px) {
  .accommodation-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .accommodation-left-section,
  .accommodation-right-section {
    text-align: center;
    align-items: center;
  }

  .accommodation-main-title,
  .accommodation-sub-title {
    font-family: 'Playfair Display', serif;
    font-size: 28px;
  }

  .accommodation-description {
    font-size: 14px;
    max-width: 100%;
    text-align: center;
  }

  .accommodation-arrow {
    display: none; /* Hide arrow on mobile screens */
  }

  .accommodation-right-section {
    margin-left: 0px;
    align-items: center;
  }

  .accommodation-acomslideshow {
    width: 90%;
    margin: 0 auto;
  }

  .accommodation-left-section {
    border-left: none;
    padding-left: 0;
    align-items: center;
  }
}